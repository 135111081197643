<template>
  <div>
    <v-app id="loading">
      <v-main>
        <div class="container-fluid fill-height">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <v-icon>mdi-loading mdi-spin</v-icon>
          </div>
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  components: {},
  methods: {
    fullLogOut() {
      this.$store.dispatch('auth/logout');
      const routeName = this.$router.currentRoute.name;
      if (routeName === 'landing') {
        this.$router.go(0);
      } else {
        this.$router.push('/');
      }
    },
  },
  mounted() {
    this.fullLogOut();
  },
};
</script>

<style lang="scss" scoped></style>
